<template>

        <!-- Modal -->
        <div class="modal fade"  :class="{ show: false, 'd-block': false }"  id="convert_smsModal"    aria-labelledby="convert_smsModalLabel" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%; max-width: 80%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="convert_smsModalLabel" v-if="convert_sms_view_type=='add'">Add Convert SMS</h5>
                <h5 class="modal-title" id="convert_smsModalLabel" v-else-if="convert_sms_view_type=='edit'">Edit Convert SMS</h5>
                <h5 class="modal-title" id="convert_smsModalLabel" v-else-if="convert_sms_view_type=='view'">View Convert SMS</h5>
                <button type="button" class="btn-close" :data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#convert_smsModal" aria-label="Close"></button>
            </div>
              <div v-if="convert_sms_view_type=='add' || convert_sms_view_type=='edit'">
              <form class="register-form" @submit.prevent="reg()">
            <div class="modal-body">

                <div class="card shadow-sm" id="stylized">
                   
                                <!-- <div class="card-body"> -->


                    <div class="card-body">
                      <h1 v-if="convert_sms_view_type == 'add'">Add Message</h1>
                      <h1 v-else-if="convert_sms_view_type == 'edit'">Edit Church</h1>
                      <br/>
                      <p></p>
        
                  <span>
                  
           




                      <div class="row" style="margin-bottom:10px">

                        
                           <div class="col">
                        <label><span class="text_error">*</span>Message (NB: "*" will be replace by converts fullname)</label>
                        <div class="input-group">
                          

                          <textarea
                            type="text"
                            class="form-control"
                            rows="4"
                            cols="50"
                            :class="{ form_error: messageErr}"
                            placeholder="Message"
                            @Keyup="messageVal($event.target.value)" 
                            v-model.trim="formData.message"
                          ></textarea>
                         
                        </div>
                        <div v-if="message_count">Word Count: {{message_count}}</div>
                         <div
                            :class="{ text_error: messageErr  }"
                           v-if="messageErr">
                          {{ messageErr }} 
                          </div>
                      </div>



                      </div>





                  </span>
                    </div>



                    

                                </div>
                
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" v-if="convert_sms_view_type=='add'">Submit</button>
                <button type="submit" class="btn btn-primary" v-if="convert_sms_view_type=='edit'">Update</button>
                <button type="button" class="btn btn-danger" @click="remove_all" v-if="convert_sms_view_type=='add'">Clear All</button>
                <button type="button" class="btn btn-secondary" ref="convert_sms_modal_ref" data-bs-dismiss="modal" v-if="convert_sms_view_type=='edit'">Close</button>
            </div>

                  </form>
                  </div>


                 <div v-else>
                      <div class="modal-body">
                        <div class="table-responsive table-responsive-data2">
                        <table class="table table-hover table-striped">
                          
                              <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Value</th>
                                </tr>
                                </thead>


                                <tbody>
                                <tr>
                                <td>Message</td>
                                <td>{{formData.message}}</td>
                                </tr>






                 
                              </tbody>
                                  </table>









                                                                

                    
                        </div>

                      </div>

                 </div>


            </div>
        </div>


        
        </div>
</template>

<script>
 import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
import notificationBox from "@/services/notificationBox";
//import SpinnerComp from "@/components/SpinnerComp.vue";
import { mapActions, mapMutations, mapState } from 'vuex';
import { DEPENDENT_LINK_REG, EDIT_DEPENDENT_LINK_REG, LOADING, RESET_DEPENDENT_SEARCH_RESULT, SEARCH_DEPENDENT } from '@/store/storeconstants';
//import VueMultiselect from 'vue-multiselect'
//import Select2 from 'vue3-select2-component';
// import vSelect from 'vue-select'

    export default {

        name:'ConvertSmsModals',
        props:{
            convert_sms_view_type:{
                required:true,
            },

            convert_sms_data:{
                required:true,
            }
        },


           mounted(){

              // const input = document.querySelector("#telephone");
              // console.log(input);
              // intlTelInput(input,{
              //   // any initialisation options go here
              //       preferredCountries: ["gh", "us", "ca"],
                
                  
              // });

             //   console.log(localStorage.getItem("token"));

           },

        async created() {




  

   this.getPortalData();




          
    //  try{
    //    let response = await axios.get("getActiveEvangelismType?church_id=" + this.formData.church_id);
    //  // console.log(response.data.data);
    //  // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
    //     this.formData.evangelism_type_id =  JSON.parse(JSON.stringify(response.data.data));
    //     //console.log("meeeeeeee");
    
      
    // }catch(error){
    //     console.log(error);
    // }


  
 

        },




         data: function () {
    return {
      //userModal: null,
      link_val:"",
      app_portal: "",
      loading:false,
      errorMess: "",
      succMess:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
      getDenominations:[],
      message_count:null,
       modal:"modal",


   
      

      formData: {
        portal:"",
        message:"",
        convert_sms_id:"",
        church_id: "",
        church: "",
        view_type:"",
        
        
      },



    //Errors  
      messageErr: "",







    };
  },


      methods: {

          ...mapActions({
            search_dependent: 'dependent/'+SEARCH_DEPENDENT,
            link_dependent_support: 'dependent/'+DEPENDENT_LINK_REG,
            edit_link_dependent_support:'dependent/'+EDIT_DEPENDENT_LINK_REG
          }),




         ...mapMutations({
                 reset_dependent_search_result: 'dependent/'+RESET_DEPENDENT_SEARCH_RESULT,
                 showLoading:LOADING,
         }),







       async reg(){
      
      //notificationBox.successAlert("Registration Successful","An Email Has Been Sent To You. Please Click On The Link To Verify Your Account") 
       //console.log(this.formData);
       // console.log(this.errorClient);
        this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);

        this.messageVal(this.formData.message);


   

             if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }

   
   else
{


    if(this.convert_sms_view_type=="add"){


         try{
            const response = await axios.post("convert_sms",this.formData);
              if(response.status == 200)
              {
                   this.showLoading(false);

                     this.clearFormData();
        notificationBox.succNotifications("Convert Sms Added Successfully");
        this.$emit("convert_smsAdded");
              }


         }catch(error){

               //  console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

                  if(this.errorServers.length > 0)
       {
          
            this.getServerError();
     
       }

         }
 



    }
    else if(this.convert_sms_view_type=="edit")
    {





         try{
            const response = await axios.patch(`convert_sms/${this.formData.convert_sms_id}`,this.formData);
              if(response.status == 200)
              {

                     this.showLoading(false);
                     this.$refs.convert_sms_modal_ref.click();
                     this.clearFormData();
        notificationBox.succNotifications("Convert Sms Edited Successfully");
        this.$emit("convert_smsAdded");
              }


         }catch(error){

                // console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           notificationBox.errorNotifications("Some Errors Exist");

                  if(this.errorServers.length > 0)
       {
          
            this.getServerError();
     
       }

         }
     

           


    }



}
    
    },





     clearFormData(){
        this.errorServers = [];
        this.errorClient = [];
      //this.clear_user = false;
      this.formData.message = "";
      

      this.getPortalData();

    },




   getServerError(){

       this.messageErr = 'message' in this.errorServers[0] ? this.errorServers[0].message[0] : '';



     
           return true;

   },





  



         messageVal(value){

      let field = "Message"

         if(FieldValidateService.reqVal(value, field))
     {
       this.messageErr = FieldValidateService.reqVal(value, field);
        this.errorClient.push(this.messageErr);
     }else{

           let pos = this.errorClient.indexOf(this.messageErr);
          
           this.errorClient.splice(pos,1);
          this.errorClient.pop(this.messageErr);
           this.messageErr =""; 

            this.message_count = value.length; 

     }


    },









  getPortalData(){
      if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
           this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
           this.formData.portal = this.app_portal;
          
            if(this.app_portal == "CHURCH")
 {
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church;
 }

         }



         else
         {
           this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
           this.formData.portal = this.app_portal;
            if(this.app_portal == "CHURCH")
 {
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info")).portal_church;
 }
//   else if(this.app_portal == "DENOMINATION")
//  {
//   this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
//   this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination;
//  }
         }

  }







    




      





  

      },

      
  computed:{


    ...mapState('dependent',['dependent_view_type','dependent_info','search_message','search_dependent_result']),
    ...mapState('dependent_support',['dependent_support_view_type','dependent_support_info']),

    // firstName(){
    //   return this.checked_user_data.fullname;
    // }
  },  

    components: {
      //SpinnerComp,
     //  vSelect
      //VueMultiselect
     // Select2
    },

    watch:{

 


   




        convert_sms_view_type(value){
          
           if(value == "add"){
        console.log(value);
           this.clearFormData();



           }
        },

        convert_sms_data(value){
       
         let data = value;
            //console.log(data);
            this.formData.convert_sms_id = data.id;
            this.formData.message = data.message;
            this.formData.church_id = data.church_id;
            this.formData.church = data.church;

       
          
          
        },


    



    }
        
    }
</script>

<style scoped>



</style>