<template>

 <!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css" integrity="sha256-mmgLkCYLUQbXn0B1SRqzHar6dCnv9oZFPEC1g1cwlkk=" crossorigin="anonymous" /> -->

<div class="col-md-12">
     <div class="row mb-3">
      <div class="col-xl-12 col-lg-12">
        <center><h3>T.H.M - LIFTING UP THE TRUTH TO THE WORLD</h3></center> 
      </div>
     </div>

    <div class="row">
      <div class="col-xl-4 col-lg-4">
         <h5 class="float-left">Welcome - <b>{{ user_portal[0].fullname.split(" ")[0] }}</b></h5>
      </div>

        <div class="col-xl-4 col-lg-4">
       
      </div>

       <div class="col-xl-4 col-lg-4">
        <a href="/" @click="handleLogOut"><h5 class="float-right">logout <i class="fas fa-sign-out"></i></h5></a>
      </div>

    </div>
    <div class="row">
        
        <!-- <div style="margin-right:70%" class="float-left"><b>Click On Portal You Will Like To Access</b></div><center><h3>Welcome - <b>Username</b></h3></center><div style="margin-left:70%; margin-bottom:60px" class="float-left"><b>Click On Portal You Will Like To Access</b></div> -->
        <div class="col-xl-4 col-lg-4"  v-for="uportal in user_portal" :key="uportal.id">
            <a style="cursor:pointer" @click="getPortal(uportal.portal_id)">
            <div class="card l-bg-cherry" :class="uportal.portal == 'MAIN' ? 'l-bg-cherry'  : 'l-bg-blue-dark'">
                <div class="card-statistic-3 p-4">
                    <div class="card-icon card-icon-large"><i class="fas fa-church"></i></div>
                    <div class="mb-4">
                        <h5 class="card-title mb-0">{{uportal.portal}}</h5>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h5 class="d-flex align-items-center mb-0" v-if="uportal.portal=='MAIN'">
                                T.H.M                        
                            </h5>
                            <h5 class="d-flex align-items-center mb-0" v-else-if="uportal.portal=='DENOMINATION'">
                                {{ uportal.portal_denomination }}                       
                            </h5>
                            <h5 class="d-flex align-items-center mb-0" v-else>
                                {{ uportal.portal_church }}                       
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <span> <i class="fa fa-user"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            </a>
        </div>
        <!-- <div class="col-xl-4 col-lg-4">
            <div class="card l-bg-blue-dark">
                <div class="card-statistic-3 p-4">
                    <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                    <div class="mb-4">
                        <h5 class="card-title mb-0">DENOMINATION</h5>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h5 class="d-flex align-items-center mb-0">
                                Presbyterian Church Of Ghana
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <span> <i class="fa fa-user"></i></span>
                        </div>
                    </div>
                
                </div>
            </div>
        </div> -->
        <!-- <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-green-dark">
                <div class="card-statistic-3 p-4">
                    <div class="card-icon card-icon-large"><i class="fas fa-ticket-alt"></i></div>
                    <div class="mb-4">
                        <h5 class="card-title mb-0">CHURCH</h5>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0">
                                St Mark Presbyterian Church Of Ghana
                            </h2>
                        </div>
                        <div class="col-4 text-right">
                            <span> <i class="fa fa-user"></i></span>
                        </div>
                    </div>
                
                </div>
            </div>
        </div> -->
        <!-- <div class="col-xl-4 col-lg-6">
            <div class="card l-bg-orange-dark">
                <div class="card-statistic-3 p-4">
                    <div class="card-icon card-icon-large"><i class="fas fa-dollar-sign"></i></div>
                    <div class="mb-4">
                        <h5 class="card-title mb-0">Revenue Today</h5>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0">
                                $11.61k
                            </h2>
                        </div>
                        <div class="col-4 text-right">
                            <span>2.5% <i class="fa fa-arrow-up"></i></span>
                        </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
                        <div class="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>
	
</template>

<script>
//import axios from 'axios';
// import { mapActions, mapMutations, mapState } from 'vuex';
// import notificationBox from '@/services/notificationBox';
// import { AUTH_LOGIN, LOADING } from '@/store/storeconstants';
// //import vSelect from 'vue-select'
// import Tilt from 'vanilla-tilt-vue';

  




export default {    
  name: "MainMenuView",

  data(){
    return{
     user_portal:[]
    }
  },

  components: {
   // Tilt
  //  vSelect,
  },


  created(){
       this.user_portal = JSON.parse(localStorage.getItem("portals"));
   //    console.log(this.user_portal);
       
  },


  methods:{
      getPortal(port_id)
    {
        
         let newUserPortal = this.user_portal.filter(portalItem => portalItem.portal_id == port_id );
        // const data = JSON.parse(newUserPortal);
  
//         if(Array.isArray(newUserPortal)) {
// 	console.log("TRUE")
// } else{
//     console.log("FALSE");
// }
         localStorage.setItem("user_portal_info",JSON.stringify(newUserPortal));

  

     //   console.log(data[0].fullname);
        
       // console.log(data.fullname)
        // console.log(JSON.stringify(userPort));

        newUserPortal = JSON.parse(JSON.stringify(newUserPortal))
        // console.log(newUserPortal[0].user_level)

         if(newUserPortal[0].user_level == "SUPER ADMIN" || newUserPortal[0].user_level == "DISTRICT ADMIN" || newUserPortal[0].user_level == "SUPER SECRETARY" || newUserPortal[0].user_level == "DISTRICT SECRETARY"){
                 window.location.href = '/convert_report';
               
            }else{
       
            //this.$router.push({name:"convert"})
              window.location.href = '/convert';
            }
    },


      handleLogOut(){

         localStorage.clear();
         this.$router.push("/")

  
    },
  }
  
}
</script>

<style scoped>

.card {
  background-color: #fff;
  border-radius: 10px;
  height: 250px;
  border: none;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}
.l-bg-cherry {
  background: linear-gradient(to right, #493240, #f09) !important;
  color: #fff;
}

.l-bg-blue-dark {
  background: linear-gradient(to right, #373b44, #4286f4) !important;
  color: #fff;
}

.l-bg-green-dark {
  background: linear-gradient(to right, #0a504a, #38ef7d) !important;
  color: #fff;
}

.l-bg-orange-dark {
  background: linear-gradient(to right, #a86008, #ffba56) !important;
  color: #fff;
}

.card .card-statistic-3 .card-icon-large .fas, .card .card-statistic-3 .card-icon-large .far, .card .card-statistic-3 .card-icon-large .fab, .card .card-statistic-3 .card-icon-large .fal {
  font-size: 110px;
}

.card .card-statistic-3 .card-icon {
  text-align: center;
  line-height: 50px;
  margin-left: 15px;
  color: #000;
  position: absolute;
  right: -5px;
  top: 20px;
  opacity: 0.1;
}

.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}

.l-bg-green {
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
  color: #fff;
}

.l-bg-orange {
  background: linear-gradient(to right, #f9900e, #ffba56) !important;
  color: #fff;
}

.l-bg-cyan {
  background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
  color: #fff;
}



</style>