<template>

        <!-- Modal -->
        <div class="modal fade"  :class="{ show: false, 'd-block': false }"  id="linkUserPortalModal"    aria-labelledby="linkUserPortalModalLabel" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%; max-width: 80%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="linkUserPortalModalLabel">Link User To Portal</h5>
                <button type="button" ref="linkUserPortalModal" class="btn-close" :data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#linkUserPortalModal" aria-label="Close"></button>
            </div>
              <div>
              <form class="register-form" @submit.prevent="link()">
            <div class="modal-body">

                <div class="card shadow-sm" id="stylized">
                   
                                <!-- <div class="card-body"> -->


                    <div class="card-body">
                      <h1>User Info <!---  {{formData.link}} --></h1><br/>
                      <p></p>
        
                  <span>

                    <div class="row" style="margin-bottom:10px" v-if="app_portal=='MAIN' ||  app_portal=='district'">

                        <div class="col" v-if="app_portal=='MAIN'">
                        <label>District</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            readonly
                            placeholder="District"
                            v-model.trim="formData.district"
                          />
                         
                        </div>
                      </div>





                       <div class="col" v-if="app_portal=='MAIN' || app_portal=='DISTRICT'">
                        <label>Church</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            readonly
                            placeholder="church"
                            v-model.trim="formData.church"
                          />
                         
                        </div>
                      </div>




                       


                 
                     


                    </div>
                  
                    <div class="row" style="margin-bottom:10px">
                      <div class="col">
                        <label>Fullname</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: fullnameErr}"
                            readonly
                            placeholder="Full Name"
                            @blur="fullnameVal($event.target.value)" 
                            v-model.trim="formData.fullname"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: fullnameErr  }"
                           v-if="fullnameErr">
                          {{ fullnameErr }} 
                          </div>
                      </div>






                      

                              <div class="col">
                        <label>Gender</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: genderErr}"
                            readonly
                            placeholder="Gender"
                            @blur="genderVal($event.target.value)" 
                            v-model.trim="formData.gender"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: genderErr  }"
                           v-if="genderErr">
                          {{ genderErr }} 
                          </div>
                      </div>



                    </div>


                           <div class="row">


                           <div class="col">
                        <label>Contact</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: contactErr}"
                            readonly
                            placeholder="Contact"
                            @blur="contactVal($event.target.value)" 
                            v-model.trim="formData.contact"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: contactErr  }"
                           v-if="contactErr">
                          {{ contactErr }} 
                          </div>
                      </div>








                        <div class="col">
                        <label>Email</label>
                        <div class="input-group">
                          
                          <input
                            type="email"
                            class="form-control"
                            :class="{ form_error: emailErr}"
                            readonly
                            placeholder="Email"
                            @blur="emailVal($event.target.value)"
                            v-model.trim.lazy="formData.email"
                          />
                         
                            
                        </div>
                         <div
                            :class="{ text_error: emailErr}"
                            v-if="emailErr"
                          >
                            {{ emailErr }} 
                          </div><br/>

                            
                      </div>


                    </div>



         
                
                  </span>
                




                      <span>
                        <h1>User Portal Info</h1><br/>
                      <p></p>
                    <div class="row" style="margin-bottom:10px">

                          <div class="col" v-if="app_portal=='MAIN' || app_portal=='DISTRICT'">
                              <label>Portal</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: portalErr}"
                             @change="portalVal($event.target.value)"
                            v-model.trim="formData.portal"
                            v-if="app_portal=='MAIN'"
                            >
                            <option value="">--Choose An Option--</option>
                            <option value="MAIN">MAIN</option>
                            <option value="DISTRICT">DISTRICT</option>
                            <option value="CHURCH">CHURCH</option>
                          </select>


                            <select
                            class="form-control"
                            :class="{ form_error: portalErr}"
                             @change="portalVal($event.target.value)"
                            v-model.trim="formData.portal"
                            v-else-if="app_portal=='DISTRICT'"
                            >
                            <option value="">--Choose An Option--</option>
                            <option value="DISTRICT">DISTRICT</option>
                            <option value="CHURCH">CHURCH</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: genderErr}"
                            v-if="genderErr"
                          >
                            {{ genderErr }}
                          </div>
                      </div>




                       <div class="col" v-if="formData.portal=='DISTRICT' && app_portal=='MAIN'">
                                <label><span class="text_error">*</span>Portal District</label>
                             <div class="input-group">
                          
                         <v-select :options="getPortalDistricts"  class="form-control select1" v-model="formData.portal_district_id"  :reduce="district => district.id" label="district" @option:selected="portal_districtVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: portal_districtErr }"
                            v-if="portal_districtErr"
                          >
                            {{ portal_districtErr }}
                          </div>
                        </div>


                      <div class="col" v-if="formData.portal=='CHURCH' && (app_portal=='MAIN' || app_portal=='DISTRICT')">
                                <label><span class="text_error">*</span>Portal Church</label>
                             <div class="input-group">
                          
                         <v-select :options="getPortalChurches"  class="form-control select1" v-model="formData.portal_church_id"  :reduce="church => church.id" label="church" @option:selected="portal_churchVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: portal_churchErr }"
                            v-if="portal_churchErr"
                          >
                            {{ portal_churchErr }}
                          </div>
                        </div>



                            <div class="col" v-if="formData.portal=='MAIN' || formData.portal=='DISTRICT' || formData.portal=='CHURCH'">
                        <label>User Level </label>
                        <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: user_levelErr || 'user_level' in errorServers }"
                            @change="user_levelVal($event.target.value)"
                            v-model.trim="formData.user_level"
                            v-if="formData.portal=='MAIN'"
                            >
                            
                            <option value="" selected>--Choose An Option--</option>
                           <option value="SUPER ADMIN">SUPER ADMIN</option>
                            <option value="SUPER SECRETARY">SUPER SECRETARY</option>
             
                          </select>

                                <select
                            class="form-control"
                            :class="{ form_error: user_levelErr || 'user_level' in errorServers }"
                            @change="user_levelVal($event.target.value)"
                            v-model.trim="formData.user_level"
                            v-else-if="formData.portal=='DISTRICT'"
                            >
                            
                            <option value="" selected>--Choose An Option--</option>
                           <option value="DISTRICT ADMIN">DISTRICT ADMIN</option>
                            <option value="DISTRICT SECRETARY">DISTRICT SECRETARY</option>
             
                          </select>


                           <select
                            class="form-control"
                            :class="{ form_error: user_levelErr || 'user_level' in errorServers }"
                            @change="user_levelVal($event.target.value)"
                            v-model.trim="formData.user_level"
                            v-else-if="formData.portal=='CHURCH'"
                            >
                            
                            <option value="" selected>--Choose An Option--</option>
                            <option value="CHURCH ADMIN">CHURCH ADMIN</option>
                            <option value="CHURCH SECRETARY">CHURCH SECRETARY</option>
                            <option value="CHURCH DATA INPUTTER">CHURCH DATA INPUTTER</option>
             
                          </select>
                          
                        </div>
                         <div :class="{ text_error: user_levelErr }" v-if="user_levelErr">
                            {{ user_levelErr }} 
                          </div>
                      </div>

                    </div>






                       <div class="row">
                       <!-- <div class="col">
                          <label><span class="text_error">*</span>Password</label>
                        <div class="input-group">
                          <input
                            v-bind:type="[showPassword ? 'text' : 'password']"
                            class="form-control"
                            :class="{ form_error: passwordErr }"
                            placeholder="Password"
                             @blur="passwordVal($event.target.value)"
                            v-model.trim="formData.password"
                          />
                              <i class="fa" @click="showPassword = !showPassword"   :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']" style="margin-top:10px; margin-left:-30px; z-index:999;"  aria-hidden="true" ></i>
                         
                        </div>
                         <div
                            :class="{ text_error: passwordErr}"
                            v-if="passwordErr"
                          >
                            {{ passwordErr }}
                          </div>
                      </div>



                      <div class="col">
                        <label><span class="text_error">*</span>Confirm Password</label>
                        <div class="input-group">
                          <input
                            v-bind:type="[showConfPassword ? 'text' : 'password']"
                            class="form-control"
                            :class="{ form_error: password_confirmationErr  }"
                            placeholder="Confirm Password"
                            @blur="password_confirmationVal($event.target.value)"
                            v-model.trim="formData.password_confirmation"
                          />
                           <i class="fa" @click="showConfPassword = !showConfPassword"   :class="[showConfPassword ? 'fa-eye' : 'fa-eye-slash']" style="margin-top:10px; margin-left:-30px; z-index:999;"  aria-hidden="true" ></i>

                    
                        </div>
                              <div
                            :class="{ text_error: password_confirmationErr }"
                            v-if="password_confirmationErr"
                          >
                            {{ password_confirmationErr }}
                          </div>
                      </div> -->




                       <div class="col">
                              <label>Is Active</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: is_activeErr}"
                             @change="is_activeVal($event.target.value)"
                            v-model.trim="formData.is_active">
                            <option value="">--Choose An Option--</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: is_activeErr}"
                            v-if="is_activeErr"
                          >
                            {{ is_activeErr }}
                          </div>
                      </div>


                      
                    </div>
                   
                  </span>










                  
                </div>


                    

                                </div>
                
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Link</button>
                <button type="button" class="btn btn-danger" @click="remove_all">Clear All</button>
                <button type="button" class="btn btn-secondary" ref="LinkUserPortalmodal_ref" data-bs-dismiss="modal" v-if="tt=='edit'">Close</button>
            </div>

                  </form>
                  </div>







            </div>
        </div>


           



        
        </div>
</template>

<script>
 import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
import vSelect from 'vue-select';
import notificationBox from "@/services/notificationBox";
// import intlTelInput from 'intl-tel-input';
//import SpinnerComp from "@/components/SpinnerComp.vue";
import { mapMutations } from 'vuex';
import { LOADING } from '@/store/storeconstants';


    export default {

        name:'LinkUserPortalModals',
            beforeRouteEnter(to,from,next){
    
     let user_level = "";
     if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
     const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
     user_level = user_data[0].user_level;
     //console.log(user_level);
     }
     else{
             const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
             user_level = user_data.user_level;
            
     }
   
     console.log(user_level);
    if(user_level =='SUPER ADMIN' || user_level =='DISTRICT ADMIN'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
    
  },
        props:{
     

   


          getUserPortalData:{
            required:true,
          }


        },


        components:{
        //  SpinnerComp,
          vSelect,
        },


           mounted(){



           },

        async created() {


   

  
   try{
       let response = await axios.get("getDistricts");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getDistricts =  JSON.parse(JSON.stringify(response.data.data));
        this.getPortalDistricts =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
       
       
      
    }catch(error){
        console.log(error);
    }








     try{
       let response = await axios.get("getChurches");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getPortalChurches =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
    
      
    }catch(error){
        console.log(error);
    }


// if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
//    this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
//  if(this.app_portal == "CHURCH")
//  {
// this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].church_id;
// this.formData.church = JSON.parse(localStorage.getItem("user_portal_info"))[0].church;
//  }
//   else if(this.app_portal == "DENOMINATION")
//  {
//   this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].denomination_id;
//   this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info"))[0].denomination;
//  }
// }


// else{


//      this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
//  if(this.app_portal == "CHURCH")
//  {
// this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info")).church_id;
// this.formData.church = JSON.parse(localStorage.getItem("user_portal_info")).church;
//  }
//   else if(this.app_portal == "DENOMINATION")
//  {
//   this.formData.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).denomination_id;
//   this.formData.denomination = JSON.parse(localStorage.getItem("user_portal_info")).denomination;
//  }

// }





this.getPortals();



  

 this.churchByDistrict();
  
 

        },




         data: function () {
    return {
      //userModal: null,
      tt:false,
      is_existing_user: false,
      is_existing:"NO",
      app_portal: "",
      loading:false,
      getDistricts:[],
      getChurches:[],
     // country_name:"",
      errorMess: "",
      succMess:"",
      SearcherrorServers:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
      user_idd:"",
      found_data:false,
      has_password:false,
      showPassword: false,
      showConfPassword: false,


      modal:"modal",


   
      

      formData: {
        user_portal_id:"",
        portal:"",
        district_id:"",
        district:"",
        user_id:"",
        church_id:"",
        church:"",
       country:"",
       fullname:"",
       is_active:"",
       gender:"",
       contact:"",
       email:"",
       user_level:"",
       password:"",
       password_confirmation:"",
       portal_district_id:"",
       portal_district:"",
       portal_church_id:"",
       portal_church:"",


         },



    //Errors  
      fullnameErr: "",
      districtErr:"",
      churchErr:"",
      genderErr: "",
      countryErr: "",
      emailErr:"",
      user_levelErr:"",
      contactErr:"",
      passwordErr:"",
      password_confirmationErr:"",
      is_activeErr:"",
      portal_districtErr:"",
      portal_churchErr:"",
      portalErr:"",










    };
    },


      methods: {

           ...mapMutations({
       showLoading:LOADING,
   }),


        async link(){

    
        
   
           this.showLoading(true); 
    
      this.errorServers = [];
       this.errorClient = [];
      
       this.districtVal(this.formData.district_id);
       this.churchVal(this.formData.church_id);
       this.fullnameVal(this.formData.fullname);
       this.genderVal(this.formData.gender);
       this.contactVal(this.formData.contact);
       this.emailVal(this.formData.email);
       this.portalVal(this.formData.portal);
       this.user_levelVal(this.formData.user_level);
       this.passwordVal(this.formData.password);
       this.password_confirmationVal(this.formData.password_confirmation);
       this.is_activeVal(this.formData.is_active);
       this.portal_districtVal(this.formData.portal_district_id);
       this.portal_churchVal(this.formData.portal_church_id);

       if(this.errorClient.length > 0){

            this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
        //  console.log(this.errorClient);
          return true;

       }
       else{



            try{
            const response = await axios.post("link_user_portal",this.formData);
            if(response.status == 200)
            {
                 this.showLoading(false);

                    
                  notificationBox.succNotifications("User Linked To Portal Successfully");
                  this.$emit("user_portalAdded");
                  this.$refs.LinkUserPortalmodal_ref.click();
            }
        }catch(error){

             console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
       

                  if(this.errorServers.length > 0)
       {
              notificationBox.errorNotifications("Some Errors Exist");
            this.getServerError();
     
       }
                   else if(error.response.data.data_error){
           notificationBox.errorNotifications(error.response.data.data_error);
       }

        }

       





       }


       



      
        },




        getServerError(){

        this.districtErr = 'district_id' in this.errorServers[0] ? this.errorServers[0].district_id[0] : '';
       this.churchErr = 'church_id' in this.errorServers[0] ? this.errorServers[0].church_id[0] : '';
       this.fullnameErr = 'fullname' in this.errorServers[0] ? this.errorServers[0].fullname[0] : '';
       this.genderErr = 'gender' in this.errorServers[0] ? this.errorServers[0].gender[0] : '';
       this.contactErr = 'contact' in this.errorServers[0] ? this.errorServers[0].contact[0] : '';
       this.emailErr = 'email' in this.errorServers[0] ? this.errorServers[0].email[0] : '';
       this.portalErr = 'portal' in this.errorServers[0] ? this.errorServers[0].portal[0] : '';
      this.user_levelErr = 'user_level' in this.errorServers[0] ? this.errorServers[0].user_level[0] : '';
      this.passwordErr = 'password' in this.errorServers[0] ? this.errorServers[0].password[0] : '';
      this.password_confirmationErr = 'password_confirmation' in this.errorServers[0] ? this.errorServers[0].password_confirmation[0] : '';
      this.is_activeErr = 'is_active' in this.errorServers[0] ? this.errorServers[0].is_active[0] : '';
      this.portal_districtErr = 'portal_district_id' in this.errorServers[0] ? this.errorServers[0].portal_district_id[0] : '';
      this.portal_churchErr = 'portal_church_id' in this.errorServers[0] ? this.errorServers[0].portal_church_id[0] : '';


     
           return true;
           
        },




        clearFormData(){


              this.errorServers = [];
        this.errorClient = [];
      //this.clear_user = false;
      this.formData.district = "";
      this.formData.district_id = "";
      this.formData.church = "";
      this.formData.church_id = "";
      this.formData.fullname = "";
      this.formData.gender = "";
      this.formData.contact = "";
      this.formData.email = "";
      this.formData.portal = "";
      this.formData.user_level = "";
      this.formData.password = "";
      this.formData.password_confirmation = "";
      this.formData.is_active = "";
      this.formData.portal_district_id = "";
      this.formData.portal_district = "";
      this.formData.portal_church_id = "";
      this.formData.portal_church = "";
      this.districtErr = "";
      this.churchErr = "";
      this.fullnameErr = "";
      this.genderErr = "";
      this.contactErr = "";
      this.emailErr = "";
      this.portalErr = "";
      this.user_levelErr = "";
      this.passwordErr = "";
      this.password_confirmationErr = "";
      this.is_activeErr = "";
      this.portal_districtErr = "";
      this.portal_churchErr = "";


       
        },


// Get Church By Denomination

async churchByDistrict(){
    try{

        const response = await axios.get(`getChurchByDistrict?district_id=${this.formData.district_id}`)
        this.getChurches = response.data.data;

       // console.log(this.getChurches);

    }catch(_){

        console.log(_);

    }
},

//////// form verification ///////////



 districtVal(value){

   //  let max = 499;
      let field = "Denomination";
     
     if(FieldValidateService.reqVal(value,field))
     {
       this.districtErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.districtErr);
     }else{

           if(this.errorClient.includes(this.districtErr))
           {
            let pos = this.errorClient.indexOf(this.districtErr);
           this.errorClient.splice(pos,1);
            this.districtErr =""; 
            
           }
          
          this.churchByDistrict();
          

     }

   },





    churchVal(value){

   //  let max = 499;
      let field = " Full name";
     
     if(FieldValidateService.reqVal(value,field))
     {
       this.churchErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.churchErr);
     }else{

           if(this.errorClient.includes(this.churchErr))
           {
            let pos = this.errorClient.indexOf(this.churchErr);
           this.errorClient.splice(pos,1);
            this.churchErr =""; 
           }
          
          

     }

   },






    portal_districtVal(value){

   //  let max = 499;
      let field = "Portal District";
     
     if(this.formData.portal=="DISTRICT" && FieldValidateService.reqVal(value,field))
     {
       this.portal_districtErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.portal_districtErr);
     }else{

           if(this.errorClient.includes(this.portal_districtErr))
           {
            let pos = this.errorClient.indexOf(this.portal_districtErr);
           this.errorClient.splice(pos,1);
            this.portal_districtErr =""; 
            
           }
          
          //this.churchByDenomination();
          

     }

   },








      portal_churchVal(value){

   //  let max = 499;
      let field = "Portal Church";
     
     if(this.formData.portal=="CHURCH" && FieldValidateService.reqVal(value,field))
     {
       this.portal_churchErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.portal_churchErr);
     }else{

           if(this.errorClient.includes(this.portal_churchErr))
           {
            let pos = this.errorClient.indexOf(this.portal_churchErr);
           this.errorClient.splice(pos,1);
            this.portal_churchErr =""; 
            
           }
          
          //this.churchByDenomination();
          

     }

   },






 fullnameVal(value){

     let max = 499;
      let field = " Full name";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.fullnameErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.fullnameErr);
     }else{

           if(this.errorClient.includes(this.fullnamelErr))
           {
            let pos = this.errorClient.indexOf(this.fullnameErr);
           this.errorClient.splice(pos,1);
            this.fullnameErr =""; 
           }
          
          

     }

   },




    genderVal(value){

     let max = 50;
      let field = "Gender";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.genderErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.genderErr);
     }else{

           if(this.errorClient.includes(this.genderErr))
           {
            let pos = this.errorClient.indexOf(this.genderErr);
           this.errorClient.splice(pos,1);
            this.genderErr =""; 
           }
          
          

     }

   },


   
        contactVal(){

            let field = "Contact"

           let value = this.formData.contact;

         if(FieldValidateService.reqValNum(value, field))
     {
       this.contactErr = FieldValidateService.reqValNum(value, field);
        this.errorClient.push(this.contactErr);
     }else{

          if(this.errorClient.includes(this.contactErr))
        {
        let pos = this.errorClient.indexOf(this.contactErr);
        this.errorClient.splice(pos,1);
        }
           this.contactErr =""; 

      
          

     }

    },


   
      

       emailVal(value){


                 let field = "Email"

         if(FieldValidateService.onlyEmail(value, field))
     {
       this.emailErr = FieldValidateService.onlyEmail(value,field);
        this.errorClient.push(this.emailErr);
     }else{

          if(this.errorClient.includes(this.contactErr))
        {
        let pos = this.errorClient.indexOf(this.contactErr);
        this.errorClient.splice(pos,1);
        }
           this.emailErr =""; 
      
         

     }


    },



   
        user_levelVal(value){

     let max = 19;
      let field = "User level";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.user_levelErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.user_levelErr);
     }else{

           if(this.errorClient.includes(this.user_levelErr))
           {
            let pos = this.errorClient.indexOf(this.user_levelErr);
           this.errorClient.splice(pos,1);
            this.user_levelErr =""; 
           }
          
          

     }

   },





  is_activeVal(value){

     let max = 10;
      let field = "Is Active";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.is_activeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.is_activeErr);
     }else{

           if(this.errorClient.includes(this.is_activeErr))
           {
            let pos = this.errorClient.indexOf(this.is_activeErr);
           this.errorClient.splice(pos,1);
            this.is_activeErr =""; 
           }
          
          

     }

   },





   portalVal(value){

   //  let max = 499;
      let field = " Portal";
     
     if(FieldValidateService.reqVal(value,field))
     {
       this.portalErr = FieldValidateService.reqVal(value,field);
        this.errorClient.push(this.portalErr);
     }else{

           if(this.errorClient.includes(this.portalErr))
           {
            let pos = this.errorClient.indexOf(this.portalErr);
           this.errorClient.splice(pos,1);
            this.portalErr =""; 
            //this.churchByDenomination();
           }
          
          

     }

   },






   


    passwordVal(value){

    //  let max = 19;
      let field = "Password";
     
     if(FieldValidateService.reqValPassword(value,field))
     {
       this.passwordErr = FieldValidateService.reqValPassword(value,field);
        this.errorClient.push(this.passwordErr);
     }else{

           if(this.errorClient.includes(this.passwordErr))
           {
            let pos = this.errorClient.indexOf(this.passwordErr);
           this.errorClient.splice(pos,1);
            this.passwordErr =""; 
           }
          
          

     }

   },


    


     password_confirmationVal(value){

           
          

      if(FieldValidateService.valPasswordConfirm(value, this.formData.password))
     {
       this.password_confirmationErr = FieldValidateService.valPasswordConfirm(value,this.formData.password);
        this.errorClient.push(this.password_confirmationErr);
     }else{

            if(this.errorClient.includes(this.password_confirmationErr))
        {
        let pos = this.errorClient.indexOf(this.password_confirmationErr);
        this.errorClient.splice(pos,1);
        }
           this.password_confirmationErr =""; 

     }


   },








   async getPortals(){
    
if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
   this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
 if(this.app_portal == "CHURCH")
 {
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
this.formData.portal_church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church;
 }
  else if(this.app_portal == "DISTRICT")
 {
  this.formData.district_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district_id;
  this.formData.portal_district_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district_id;
  this.formData.district = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district;

     try{
       let response = await axios.get(`getChurchByDistrict?district_id=${this.formData.portal_district_id}`);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getPortalChurches =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
    
      
    }catch(error){
        console.log(error);
    }

 }
 else{
       try{
       let response = await axios.get("getChurches");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getPortalChurches =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
    
      
    }catch(error){
        console.log(error);
    }
 }
}
else{


   this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
 if(this.app_portal == "CHURCH")
 {
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;
this.formData.portal_church_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info")).portal_church;
 }
  else if(this.app_portal == "DISTRICT")
 {
  this.formData.district_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_district_id;
  this.formData.portal_district_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_district_id;
  this.formData.district = JSON.parse(localStorage.getItem("user_portal_info")).portal_district;

       try{
       let response = await axios.get(`getChurchByDistrict?district_id=${this.formData.portal_district_id}`);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getPortalChurches =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
    
      
    }catch(error){
        console.log(error);
    }
 }

  else{
       try{
       let response = await axios.get("getChurches");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.getPortalChurches =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
    
      
    }catch(error){
        console.log(error);
    }
 }

}

   }
  

      

  






 





      },


    //    computed:{

    //     changes : {
    //         get : function(){
    //             return this.count_down;
    //         },
    //         set : function(vem){
             
    //             this.count_down =  vem;
    //              if(this.count_down <= 0){
    //             this.count_down = "";
    //              clearInterval(this.count_down);
    //           }
    //         }
    //     }
    // },

 


      watch:{
     

     


           async getUserPortalData(value){
            const data = value;

          

             this.formData.user_id = data.user_id;
             this.formData.user_portal_id = data.id;
            this.formData.district_id = data.district_id;
            this.formData.district = data.district;
              this.churchByDistrict();
            this.formData.church_id = data.church_id;
            this.formData.church = data.church;
            this.formData.fullname = data.fullname;
            this.formData.gender = data.gender;
            this.formData.contact = data.contact;
            this.formData.email = data.email;
            this.formData.password = data.password;
            this.formData.password_confirmation = data.password;

        



          }
      }
        
    }
</script>

<style scoped>


</style>