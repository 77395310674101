<template>

        <!-- Modal -->
        <div class="modal fade"  :class="{ show: false, 'd-block': false }"  id="convertModal"    aria-labelledby="convertModalLabel" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="max-height:30%; max-width: 80%;" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="convertModalLabel" v-if="convert_view_type=='add'">Add Convert</h5>
                <h5 class="modal-title" id="convertModalLabel" v-else-if="convert_view_type=='edit'">Edit Convert</h5>
                <h5 class="modal-title" id="convertModalLabel" v-else-if="convert_view_type=='view'">View Convert</h5>
                <button type="button" class="btn-close" :data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#convertModal" aria-label="Close"></button>
            </div>
              <div v-if="this.convert_view_type=='add' || this.convert_view_type=='edit'">
              <form class="register-form" @submit.prevent="reg()">
            <div class="modal-body">

                <div class="card shadow-sm" id="stylized">
                   
                                <!-- <div class="card-body"> -->


                    <div class="card-body">
                      <h1 v-if="convert_view_type == 'add'">Add Convert</h1>
                      <h1 v-else-if="convert_view_type == 'edit'">Edit Convert</h1>
                      <br/>
                      <p></p>
        
                  <span>
                  




                         <div class="row form_space" style="margin-bottom:10px" v-if="app_portal=='MAIN' ||  app_portal=='DISTRICT'">
                    <div class="col" v-if="app_portal=='MAIN'">
                                <label><span class="text_error">*</span>District</label>
                             <div class="input-group">
                          
                         <v-select :options="getDistricts"  class="form-control select1" v-model="formData.district_id"  :reduce="district => district.id" label="district" @option:selected="districtVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: districtErr }"
                            v-if="districtErr"
                          >
                            {{ districtErr }}
                          </div>
                        </div>



                 
                      <div class="col" v-if="app_portal=='MAIN' || app_portal=='DISTRICT'">
                                <label><span class="text_error">*</span>Church</label>
                             <div class="input-group">
                          

                         <v-select :options="getChurches" class="form-control select1" v-model="formData.church_id"  :reduce="church => church.id" label="church" @option:selected="churchVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: churchErr }"
                            v-if="churchErr"
                          >
                            {{ churchErr }}
                          </div>
                        </div>


                    </div>
           




                      <div class="row" style="margin-bottom:20px;">

                <div class="col">
                        <label><span class="text_error">*</span>Fullname</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: fullnameErr}"
                            placeholder="Fullname"
                            @blur="fullnameVal($event.target.value)" 
                            v-model.trim="formData.fullname"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: fullnameErr  }"
                           v-if="fullnameErr">
                          {{ fullnameErr }} 
                          </div>
                      </div>

                <div class="col">
                        <label><span class="text_error">*</span>Age</label>
                        <div class="input-group">
                          

                          <input
                            type="number"
                            class="form-control"
                            :class="{ form_error: ageErr}"
                            placeholder="Age"
                            @blur="ageVal($event.target.value)" 
                            v-model.trim="formData.age"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: ageErr  }"
                           v-if="ageErr">
                          {{ ageErr }} 
                          </div>
                      </div>







                     <div class="col">
                              <label><span class="text_error">*</span>Gender</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: genderErr}"
                             @change="genderVal($event.target.value)"
                            v-model.trim="formData.gender">
                            <option value="">--Choose An Option--</option>
                            <option value="MALE">MALE</option>
                            <option value="FEMALE">FEMALE</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: genderErr}"
                            v-if="genderErr"
                          >
                            {{ genderErr }}
                          </div>
                      </div>

         



                     




                    </div>




                    <div class="row form_space">


                         <div class="col">
                        <label><span class="text_error">*</span>Location</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: locationErr}"
                            placeholder="Location"
                            @blur="locationVal($event.target.value)" 
                            v-model.trim="formData.location"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: locationErr  }"
                           v-if="locationErr">
                          {{ locationErr }} 
                          </div>
                      </div>





                        <div class="col">
                          <label><span class="text_error">*</span>Residence / Personal Phone Number</label><br/>
                        <div class="input-group">
                          
                          <!-- <input
                            type="text"
                            class="form-control"
                            id="telephone"
                            :class="{ form_error: contactErr }"
                            placeholder="Contact"
                             @blur="contactVal($event.target.value)"
                            v-model.trim.lazy="formData.contact"
                          /> -->
                          <vue-tel-input v-model.lazy="formData.residence_phone_number" @blur="residence_phone_numberVal" ></vue-tel-input>
                          
                           

                        </div>
                        <div
                            :class="{ text_error: residence_phone_numberErr }"
                            v-if="residence_phone_numberErr"
                          >
                            {{ residence_phone_numberErr }}
                          </div><br/>

                      </div>






                                 <div class="col">
                          <label><span class="text_error"></span>Office Phone Number</label><br/>
                        <div class="input-group">
                          
                          <!-- <input
                            type="text"
                            class="form-control"
                            id="telephone"
                            :class="{ form_error: contactErr }"
                            placeholder="Contact"
                             @blur="contactVal($event.target.value)"
                            v-model.trim.lazy="formData.contact"
                          /> -->
                          <vue-tel-input v-model.lazy="formData.office_phone_number" @blur="office_phone_numberVal" ></vue-tel-input>
                          
                           

                        </div>
                        <div
                            :class="{ text_error: office_phone_numberErr }"
                            v-if="office_phone_numberErr"
                          >
                            {{ office_phone_numberErr }}
                          </div><br/>

                      </div>



                               




                    </div>





                     <div class="row form_space">


                         <div class="col">
                                <label><span class="text_error">*</span>Church Evangelism Location</label>
                             <div class="input-group">
                          
                         <v-select :options="getChurchEvangelismLocations"  class="form-control select1" v-model="formData.church_evangelism_location_id"  :reduce="church_evangelism_location => church_evangelism_location.id" label="church_evangelism_location" @option:selected="church_evangelism_locationVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: church_evangelism_locationErr }"
                            v-if="church_evangelism_locationErr"
                          >
                            {{ church_evangelism_locationErr }}
                          </div>
                        </div>


                            <div class="col">
                              <label><span class="text_error">*</span>First Time Decision For Christ</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: first_time_decision_for_christErr}"
                             @change="first_time_decision_for_christVal($event.target.value)"
                            v-model.trim="formData.first_time_decision_for_christ">
                            <option value="">--Choose An Option--</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: first_time_decision_for_christErr}"
                            v-if="first_time_decision_for_christErr"
                          >
                            {{ first_time_decision_for_christErr }}
                          </div>
                      </div>






                                 <div class="col">
                              <label><span class="text_error">*</span>Best Time To Visit</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: best_time_to_visitErr}"
                             @change="best_time_to_visitVal($event.target.value)"
                            v-model.trim="formData.best_time_to_visit">
                            <option value="">--Choose An Option--</option>
                            <option value="MORNING">MORNING</option>
                            <option value="AFTERNOON">AFTERNOON</option>
                            <option value="EVENING">EVENING</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: best_time_to_visitErr}"
                            v-if="best_time_to_visitErr"
                          >
                            {{ best_time_to_visitErr }}
                          </div>
                      </div>






                                  <div class="col">
                              <label><span class="text_error">*</span>What Is Convert's Need</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: convert_needsErr}"
                             @change="convert_needsVal($event.target.value)"
                            v-model.trim="formData.convert_needs">
                            <option value="">--Choose An Option--</option>
                            <option value="BACKSLIDE BUT HAS BEEN RESTORED TODAY">BACKSLIDE BUT HAS BEEN RESTORED TODAY</option>
                            <option value="HAS MAJOR SPIRITUAL PROBLEMS AND NEED HELP">HAS MAJOR SPIRITUAL PROBLEMS AND NEED HELP</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: convert_needsErr}"
                            v-if="convert_needsErr"
                          >
                            {{ convert_needsErr }}
                          </div>
                      </div>


                     </div>



                  </span>
                    </div>



                    

                                </div>
                
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" v-if="convert_view_type=='add'">Submit</button>
                <button type="submit" class="btn btn-primary" v-if="convert_view_type=='edit'">Update</button>
                <button type="button" class="btn btn-danger" @click="remove_all" v-if="convert_view_type=='add'">Clear All</button>
                <button type="button" class="btn btn-secondary" ref="convert_modal_ref" data-bs-dismiss="modal" v-if="convert_view_type=='edit'">Close</button>
            </div>

                  </form>
                  </div>


                 <div v-else>
                      <div class="modal-body">
                        <div class="table-responsive table-responsive-data2">
                        <table class="table table-hover table-striped">
                          
                              <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Value</th>
                                </tr>
                                </thead>


                                <tbody>
                                <tr>
                                <td>District</td>
                                <td>{{formData.district}}</td>
                                </tr>

                                <tr>
                                <td>Church</td>
                                <td>{{formData.church}}</td>
                                </tr>



                                 <tr>
                                <td>Fullname</td>
                                <td>{{formData.fullname}}</td>
                                </tr>

                                 <tr>
                                <td>Age</td>
                                <td>{{formData.age}}</td>
                                </tr>


                                 <tr>
                                <td>Gender</td>
                                <td>{{formData.gender}}</td>
                                </tr>


                                 <tr>
                                <td>Church Evangelism Location</td>
                                <td>{{formData.location}}</td>
                                </tr>

                                 <tr>
                                <td>Residence Phone Number</td>
                                <td>{{formData.residence_phone_number}}</td>
                                </tr>


                                 <tr>
                                <td>Office Phone Number</td>
                                <td>{{formData.office_phone_number}}</td>
                                </tr>

                                 <tr>
                                <td>Church Evangelism Location</td>
                                <td>{{formData.church_evangelism_location}}</td>
                                </tr>



                                 <tr>

                                <td>First Time Decision For Christ</td>
                                <td>{{formData.first_time_decision_for_christ}}</td>
                                </tr>



                                 <tr>

                                <td>Best Time To Visit Convert</td>
                                <td>{{formData.best_time_to_visit}}</td>
                                </tr>




                               <tr>
                                <td>What Is Convert's Need</td>
                                <td>{{formData.convert_needs}}</td>
                                </tr>




                 
                              </tbody>
                                  </table>









                                                                

                    
                        </div>

                      </div>

                 </div>


            </div>
        </div>


        
        </div>
</template>

<script>
 import axios from 'axios';
import FieldValidateService from "@/services/FieldValidateService";
import notificationBox from "@/services/notificationBox";
//import SpinnerComp from "@/components/SpinnerComp.vue";
import { mapActions, mapMutations } from 'vuex';
import { DEPENDENT_LINK_REG, EDIT_DEPENDENT_LINK_REG, LOADING, RESET_DEPENDENT_SEARCH_RESULT, SEARCH_DEPENDENT } from '@/store/storeconstants';
//import VueMultiselect from 'vue-multiselect'
//import Select2 from 'vue3-select2-component';
import vSelect from 'vue-select';

    export default {

        name:'ConvertModals',
        props:{
            convert_view_type:{
                required:true,
            },

            convert_data:{
                required:true,
            }
        },


           mounted(){

              // const input = document.querySelector("#telephone");
              // console.log(input);
              // intlTelInput(input,{
              //   // any initialisation options go here
              //       preferredCountries: ["gh", "us", "ca"],
                
                  
              // });

             //   console.log(localStorage.getItem("token"));

           },

        async created() {


              //getCrusade TypeActive
  





      try{
       let response = await axios.get("getDistricts");
        this.getDistricts =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
       
      
    }catch(error){
        console.log(error);
    }





 






    //  try{
    //    let response = await axios.get("getChurches");
    //  // console.log(response.data.data);
    //  // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
    //     this.getPortalChurches =  JSON.parse(JSON.stringify(response.data.data));
    //     //console.log("meeeeeeee");
    
      
    // }catch(error){
    //     console.log(error);
    // }




 








  this.getPortal();

 this.churchByDistrict();
  

          try{
       let response = await axios.get(`getChurchEvangelismLocations?church_id=${this.formData.church_id}`);
        this.getChurchEvangelismLocations =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
       
      
    }catch(error){
        console.log(error);
    }

 
  
 

        },




         data: function () {
    return {
      //userModal: null,
      link_val:"",
      app_portal: "",
      loading:false,
      errorMess: "",
      succMess:"",
      clear_user: true,
      clear_all: true,
      errorClient:[],
      errorServers:[],
      getDistricts:[],
      getChurchEvangelismLocations:[],
      getChurches:[],
       modal:"modal",


   
      

      formData: {
        portal:"",
        evangelism_type_id:"",
        convert_id:"",
        district_id:"",
        district:"",
        church_evangelism_location_id:"",
        church_evangelism_location:"",
        church_id:"",
        church:"",
        fullname:"",
        age:"",
        gender:"",
        location:"",
        office_phone_number:"",
        residence_phone_number:"",
        first_time_decision_for_christ:"",
        best_time_to_visit:"",
        convert_needs:"",

        view_type:"",
        user_id:1,
        
        
      },



    //Errors  
      districtErr: "",
      churchErr: "",
      fullnameErr: "",
      ageErr: "",
      genderErr: "",
      locationErr: "",
      church_evangelism_locationErr: "",
      residence_phone_numberErr: "",
      office_phone_numberErr: "",
      first_time_decision_for_christErr: "",
      best_time_to_visitErr: "",
      convert_needsErr: "",






    };
  },


      methods: {

          ...mapActions({
            search_dependent: 'dependent/'+SEARCH_DEPENDENT,
            link_dependent_support: 'dependent/'+DEPENDENT_LINK_REG,
            edit_link_dependent_support:'dependent/'+EDIT_DEPENDENT_LINK_REG
          }),




         ...mapMutations({
                 reset_dependent_search_result: 'dependent/'+RESET_DEPENDENT_SEARCH_RESULT,
                 showLoading:LOADING,
         }),







       async reg(){
      
      //notificationBox.successAlert("Registration Successful","An Email Has Been Sent To You. Please Click On The Link To Verify Your Account") 
       //console.log(this.formData);
       // console.log(this.errorClient);
        this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);

        // this.crusade_typeVal(this.formData.crusade_type);
        // this.is_activeVal(this.formData.is_active);

        //this.districtVal(this.formData.district_id);
       // this.churchVal(this.formData.church_id);
        this.fullnameVal(this.formData.fullname);
        this.genderVal(this.formData.gender);
        this.ageVal(this.formData.age);
        this.locationVal(this.formData.location);
        this.church_evangelism_locationVal(this.formData.church_evangelism_location_id);
        this.office_phone_numberVal(this.formData.office_phone_number);
        this.residence_phone_numberVal(this.formData.residence_phone_number);
        this.first_time_decision_for_christVal(this.formData.first_time_decision_for_christ);
        this.best_time_to_visitVal(this.formData.best_time_to_visit);
        this.convert_needsVal(this.formData.convert_needs);


   

             if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }

   
     else{



    if(this.convert_view_type=="add"){


         try{
            const response = await axios.post("converts",this.formData);
              if(response.status == 200)
              {
                   this.showLoading(false);

                   
                     console.log(response);
                if(response.data.message == "success")
                {
        notificationBox.succNotifications("Convert Added Successfully");
          this.clearFormData();
        this.$emit("convertAdded");
                }else if(response.data.message == "success with warning")
                {
                  notificationBox.succNotifications("Convert Added Successfully But Please Recharge Your Sms Inorder To Send SMS");
                    this.clearFormData();
                 this.$emit("convertAdded");
              }
              else{
                 this.showLoading(false);
                notificationBox.errorNotifications(response.data.data_error);

              }
        

              }


         }catch(error){

                 console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
           

        if(error.response.data.data_error){
        
           notificationBox.errorNotifications(error.response.data.data_error);
       }


               else  if(this.errorServers.length > 0)
       {
          notificationBox.errorNotifications("Some Errors Exist");
            this.getServerError();
     
       }
    

         }
 



    }
    else if(this.convert_view_type=="edit")
    {





         try{
            const response = await axios.patch(`converts/${this.formData.convert_id}`,this.formData);
              if(response.status == 200)
              {

                     this.showLoading(false);
                     this.$refs.convert_modal_ref.click();
                     this.clearFormData();
                if(response.data.message == "success")
                {
        notificationBox.succNotifications("Convert Edited Successfully");
                }
                else{
          notificationBox.warnNotifications("Convert Edited Successfully");
                }
        this.$emit("convertAdded");
              }


         }catch(error){

                console.log(error);
           this.errorServers.push(error.response.data.error);
           
           this.showLoading(false);
         


            if(error.response.data.data_error){
           notificationBox.errorNotifications(error.response.data.data_error);
       }

       
                else  if(this.errorServers.length > 0)
       {
            notificationBox.errorNotifications("Some Errors Exist");
            this.getServerError();
     
       }

         }
     

           
    }

    }




    
    },





     clearFormData(){
        this.errorServers = [];
        this.errorClient = [];
      //this.clear_user = false;
      this.formData.evangelism_type_id = "";
      this.formData.fullname = "";
      this.formData.district_id = "";
      this.formData.district = "";
      this.formData.church_id = "";
      this.formData.church = "";
      this.formData.age = "";
      this.formData.gender = "";
      this.formData.location = "";
      this.formData.church_evangelism_location_id = "";
      this.formData.church_evangelism_location = "";
    this.formData.office_phone_number="",
    this.formData.residence_phone_number="",
    this.formData.first_time_decision_for_christ="",
    this.formData.best_time_to_visit="",
    this.formData.convert_needs="",


      this.fullnameErr = "";
      this.districtErr = "";
      this.churchErr = "";
      this.ageErr = "";
      this.genderErr = "";
      this.locationErr = "";
      this.church_evangelism_locationErr = "";
    this.office_phone_numberErr="",
    this.residence_phone_numberErr="",
    this.first_time_decision_for_christErr="",
    this.best_time_to_visitErr="",
    this.convert_needsErr="",

    this.getPortal();


    },




   getServerError(){

       //this.districtErr = 'district_id' in this.errorServers[0] ? this.errorServers[0].district_id[0] : '';
       //this.churchErr = 'church_id' in this.errorServers[0] ? this.errorServers[0].church_id[0] : '';
       this.fullnameErr = 'fullname' in this.errorServers[0] ? this.errorServers[0].fullname[0] : '';
       this.ageErr = 'age' in this.errorServers[0] ? this.errorServers[0].age[0] : '';
       this.genderErr = 'gender' in this.errorServers[0] ? this.errorServers[0].gender[0] : '';
       this.locationErr = 'location' in this.errorServers[0] ? this.errorServers[0].location[0] : '';
       this.church_evangelism_locationErr = 'church_evangelism_location' in this.errorServers[0] ? this.errorServers[0].church_evangelism_location[0] : '';
       this.office_phone_numberErr = 'office_phone_number' in this.errorServers[0] ? this.errorServers[0].office_phone_number[0] : '';
       this.residence_phone_numberErr = 'residence_phone_number' in this.errorServers[0] ? this.errorServers[0].residence_phone_number[0] : '';
       this.first_time_decision_for_christErr = 'first_time_decision_for_christ' in this.errorServers[0] ? this.errorServers[0].first_time_decision_for_christ[0] : '';
       this.best_time_to_visitErr = 'best_time_to_visit' in this.errorServers[0] ? this.errorServers[0].best_time_to_visit[0] : '';
       this.convert_needsErr = 'convert_needs' in this.errorServers[0] ? this.errorServers[0].convert_needs[0] : '';



     
           return true;

   },




       districtVal(value){

             let field = "District";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.districtErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.districtErr);
      }else{
        if(this.errorClient.includes(this.districtErr))
        {
        let pos = this.errorClient.indexOf(this.districtErr);
        this.errorClient.splice(pos,1);
        }
        this.districtErr =""; 
         this.churchByDistrict();
      }

    },







        churchVal(value){

             let field = "Church";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.churchErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.churchErr);
      }else{
        if(this.errorClient.includes(this.churchErr))
        {
        let pos = this.errorClient.indexOf(this.churchErr);
        this.errorClient.splice(pos,1);
        }
        this.churchErr =""; 
      }

    },





     fullnameVal(value){

      let max = 500;
      let field = "Fullname"

         if(FieldValidateService.reqMaxStringVal(value, field,max))
     {
       this.fullnameErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.fullnameErr);
     }else{

           this.fullnameErr =""; 
          this.errorClient.pop(this.fullnameErr);

     }


    },







         ageVal(value){

      let field = "Age"

         if(FieldValidateService.reqValNum2(value, field))
     {
       this.ageErr = FieldValidateService.reqValNum2(value,field);
        this.errorClient.push(this.ageErr);
     }else{

           this.ageErr =""; 
          this.errorClient.pop(this.ageErr);

     }


    },








  genderVal(value){

             let field = "Gender";

       
      if(FieldValidateService.reqVal2(value,field)){
        this.genderErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.genderErr);
      }else{
        if(this.errorClient.includes(this.genderErr))
        {
        let pos = this.errorClient.indexOf(this.genderErr);
        this.errorClient.splice(pos,1);
        }
        this.genderErr =""; 
      }

    },




      locationVal(value){

             let field = "Location";

       
      if(FieldValidateService.reqVal2(value,field)){
        this.locationErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.locationErr);
      }else{
        if(this.errorClient.includes(this.locationErr))
        {
        let pos = this.errorClient.indexOf(this.locationErr);
        this.errorClient.splice(pos,1);
        }
        this.locationErr =""; 
      }

    },







         church_evangelism_locationVal(value){

             let field = "Church Evangelism Location";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.church_evangelism_locationErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.church_evangelism_locationErr);
      }else{
        if(this.errorClient.includes(this.church_evangelism_locationErr))
        {
        let pos = this.errorClient.indexOf(this.church_evangelism_locationErr);
        this.errorClient.splice(pos,1);
        }
        this.church_evangelism_locationErr =""; 
      }

    },






    office_phone_numberVal(){

  
           //this.office_phone_numberErr =""; 


               let field = "Residence / Personal Phone Number"

           let value = this.formData.office_phone_number;

           let max = 16;
           let min = 10;

         if(value.length && FieldValidateService.reqValNumMaxMin(value, field, max, min))
     {
       this.office_phone_numberErr = FieldValidateService.reqValNumMaxMin(value, field, max, min);
        this.errorClient.push(this.office_phone_numberErr);
     }else{

          if(this.errorClient.includes(this.office_phone_numberErr))
        {
        let pos = this.errorClient.indexOf(this.office_phone_numberErr);
        this.errorClient.splice(pos,1);
        }
           this.office_phone_numberErr =""; 


     }




     

    },






    








     residence_phone_numberVal(){

           let field = "Residence / Personal Phone Number"

           let value = this.formData.residence_phone_number;

           let max = 16;
           let min = 10;

         if(FieldValidateService.reqValNumMaxMin(value, field, max, min))
     {
       this.residence_phone_numberErr = FieldValidateService.reqValNumMaxMin(value, field, max, min);
        this.errorClient.push(this.residence_phone_numberErr);
     }else{

          if(this.errorClient.includes(this.residence_phone_numberErr))
        {
        let pos = this.errorClient.indexOf(this.residence_phone_numberErr);
        this.errorClient.splice(pos,1);
        }
           this.residence_phone_numberErr =""; 


     }


     

    },





    first_time_decision_for_christVal(value){

             let field = "First Time Decision For Christ";

       
      if(FieldValidateService.reqVal2(value,field)){
        this.first_time_decision_for_christErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.first_time_decision_for_christErr);
      }else{
        if(this.errorClient.includes(this.first_time_decision_for_christErr))
        {
        let pos = this.errorClient.indexOf(this.first_time_decision_for_christErr);
        this.errorClient.splice(pos,1);
        }
        this.first_time_decision_for_christErr =""; 
      }

    },






      best_time_to_visitVal(value){

             let field = "Best Time To Visit Convert";

       
      if(FieldValidateService.reqVal2(value,field)){
        this.best_time_to_visitErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.best_time_to_visitErr);
      }else{
        if(this.errorClient.includes(this.best_time_to_visitErr))
        {
        let pos = this.errorClient.indexOf(this.best_time_to_visitErr);
        this.errorClient.splice(pos,1);
        }
        this.best_time_to_visitErr =""; 
      }

    },







      convert_needsVal(value){

             let field = "What Is Convert's Need";

       
      if(FieldValidateService.reqVal2(value,field)){
        this.convert_needsErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.convert_needsErr);
      }else{
        if(this.errorClient.includes(this.convert_needsErr))
        {
        let pos = this.errorClient.indexOf(this.convert_needsErr);
        this.errorClient.splice(pos,1);
        }
        this.convert_needsErr =""; 
      }

    },
    




    // Get Church By Denomination

async churchByDistrict(){
    try{

        const response = await axios.get(`getChurchByDistrict?district_id=${this.formData.district_id}`)
        this.getChurches = response.data.data;

       // console.log(this.getChurches);

    }catch(_){

        console.log(_);

    }
},




async getPortal(){


 
if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
   this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
 if(this.app_portal == "CHURCH")
 {
this.formData.district_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district_id;
this.formData.district = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district;
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church;
 }
  else if(this.app_portal == "DISTRICT")
 {
  this.formData.district_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district_id;
  this.formData.district = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district;
 }
}
else{


     this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
 if(this.app_portal == "CHURCH")
 {
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info")).portal_church;
 }
  else if(this.app_portal == "DISTRICT")
 {
  this.formData.district_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_district_id;
  this.formData.district = JSON.parse(localStorage.getItem("user_portal_info")).portal_district;
 }

}



 try{
       let response = await axios.get(`getActiveEvangelismType?church_id=${this.formData.church_id}`);
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.formData.evangelism_type_id =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
    
      
    }catch(error){
        console.log(error);
    }




}


      





  

      },

      
  computed:{


    // ...mapState('dependent',['dependent_view_type','dependent_info','search_message','search_dependent_result']),
    // ...mapState('dependent_support',['dependent_support_view_type','dependent_support_info']),

    // firstName(){
    //   return this.checked_user_data.fullname;
    // }
  },  

    components: {
 
     vSelect
    },

    watch:{

 


   




        convert_view_type(value){
          
           if(value == "add"){
        
           this.clearFormData();


           }
        },

        convert_data(value){
       
         let data = value;
            //console.log(data);
            this.formData.evangelism_type_id = data.evangelism_type_id;
            this.formData.district_id = data.district_id;
            this.formData.convert_id = data.id;
            this.formData.district = data.district;
             this.churchByDistrict();
            this.formData.church_id = data.church_id;
            this.formData.church = data.church;
            this.formData.fullname = data.fullname;
            this.formData.age = data.age;
            this.formData.gender = data.gender;
            this.formData.location = data.location;
            this.formData.church_evangelism_location_id = data.church_evangelism_location_id;
            this.formData.church_evangelism_location = data.church_evangelism_location;
         //   this.formData.office_phone_number = data.office_phone_number;
            this.formData.office_phone_number = !data.office_phone_number ? "" : data.office_phone_number;
            this.formData.residence_phone_number = data.residence_phone_number;
            this.formData.first_time_decision_for_christ= data.first_time_decision_for_christ;
            this.formData.best_time_to_visit= data.best_time_to_visit
            this.formData.convert_needs= data.convert_needs;

        
       
          
          
        },


    



    }
        
    }
</script>

<style scoped>


</style>