<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Send Bulk Sms" pageDesc="sms setup"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                   
                                    <!-- <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" portall="" />

                    

                                    <TableSearch @searchValue="searchval" />


                                  
    


                                    <ActionButton  @deleteRecords="deleteRecords" :num_checked="checked.length" portall="" />









                                
                                    <div class="col-2 text-end">
                        

                                                   
                                        <a class="btn btn-outline-success" @click.prevent="crusade_typeAdd" ref="crusade_type_add_ref" data-bs-toggle="modal"  href="#crusade_typeModal" v-if="app_portal=='MAIN'">
                                            <i class="fas fa-plus"></i> Add Crusade Type
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="crusade_type_ref" data-bs-toggle="modal"  href="#crusade_typeModal">
                                            <i class="fas fa-plus"></i> Crusade Type Ref
                                        </a>   

                              

                                        
                                    </div>


                            


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" /> -->


                                </div>
                            </div>


                            <div class="card shadow-sm" id="stylized">
                              <form class="register-form" @submit.prevent="sendSms()">

                                <div class="card-body">



                              <div class="row form_space" style="margin-bottom:10px" v-if="app_portal=='MAIN' ||  app_portal=='DISTRICT'">
                    <div class="col" v-if="app_portal=='MAIN'">
                                <label><span class="text_error">*</span>District</label>
                             <div class="input-group">
                          
                         <v-select :options="getDistricts"  class="form-control select1" v-model="formData.district_id"  :reduce="district => district.id" label="district" @option:selected="districtVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: districtErr }"
                            v-if="districtErr"
                          >
                            {{ districtErr }}
                          </div>
                        </div>



                 
                      <div class="col" v-if="(app_portal=='MAIN' || app_portal=='DISTRICT') && formData.district_id != 'ALL'">
                                <label><span class="text_error">*</span>Church</label>
                             <div class="input-group">
                          

                         <v-select :options="getChurches" class="form-control select1" v-model="formData.church_id"  :reduce="church => church.id" label="church" @option:selected="churchVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: churchErr }"
                            v-if="churchErr"
                          >
                            {{ churchErr }}
                          </div>
                        </div>


                    </div>


                             <div class="row">



                             <div class="col" v-if="(this.formData.church_id)">
                                <label><span class="text_error">*</span>Evangelism Type</label>
                             <div class="input-group">
                          

                         <v-select :options="getEvangelismTypes" class="form-control select1" v-model="formData.evangelism_type_id"  :reduce="evangelism_type => evangelism_type.id" label="evangelism_type" @option:selected="evangelism_typeVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: evangelism_typeErr }"
                            v-if="evangelism_typeErr"
                          >
                            {{ evangelism_typeErr }}
                          </div>
                        </div>




                               <div class="col">


                                 <label><span class="text_error">*</span>Converts</label>
                             <div class="input-group">
                          

                         <v-select :options="getConvertss" multiple class="form-control select1" v-model="formData.convert_id"  :reduce="convert => convert.id" label="full_name" @option:selected="convertVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: convertErr }"
                            v-if="convertErr"
                          >
                            {{ convertErr }}
                          </div><br/>

                            <button type="submit" class="btn btn-primary">Send Sms</button>

                           
                      </div>








                       <div class="col">
                        <label><span class="text_error">*</span>Message</label>
                        <div class="input-group">
                          

                          <textarea
                            type="text"
                            class="form-control"
                            :class="{ form_error: messageErr}"
                            placeholder="Message"
                            @Keyup="messageVal($event.target.value)" 
                            v-model.trim="formData.message"
                          ></textarea>
                         
                        </div>
                        <div v-if="message_count">Word Count: {{message_count}}</div>
                         <div
                            :class="{ text_error: messageErr  }"
                           v-if="messageErr">
                          {{ messageErr }} 
                          </div>
                      </div>


                      









         



                     




                    </div>



                         
                                   
                                </div>
                                 </form>


                            <!-- Table Goes Here -->

                            </div>




                



                         




                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div> 

  


        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
// import PerPageComponent from "@/components/table/PerPage.vue"
// import TableSearch from "@/components/table/TableSearch"
// import TableFilter from "@/components/table/TableFilter"
// import ActionButton from "@/components/table/ActionButton"
// import SelectAllComponent from "@/components/table/SelectAllComponent"
import axios from "axios"
import FieldValidateService from "@/services/FieldValidateService";

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex';
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants';
import vSelect from 'vue-select';


export default {
  name: "ConvertReportView",
    beforeRouteEnter(to,from,next){
     let user_level = "";
     if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
     const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
     user_level = user_data[0].user_level;
     }
     else{
             const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
             user_level = user_data.user_level;
     }
   
    
    if(user_level=='SUPER ADMIN' || user_level=='SUPER SECRETARY' || user_level=='DISTRICT ADMIN' || user_level=='DISTRICT SECRETARY' || user_level=='CHURCH ADMIN' || user_level=='CHURCH SECRETARY'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
    
  },
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
 
    // TableFilter,

    vSelect,

  },



  data(){
    return {

      grid_converts:[],
      report_converts:{},
      totalConverts:"",
        //showUserModals:false,

        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        is_active: "",
        checked:[],
        user_table_data:{},
        user_portal_table_data:{},
        getEvangelismTypeData:{},
        view_type:"",
        app_portal:"",
        user_portal_info:{},
        getSearchBy:[],
        getColumns:[],
        getDistricts:[],
        getChurches:[],
        getConvertss:[],
        getEvangelismTypes:[],
        errorClient:[],
        errorServers:[],
        // provider_id:"",
        // country_view_type:"",
        // provider_view_type:"", 
        // employee_user_id:"",
        // employee_provider_user_id:"",
        // support_id:"",
        // dependent_id:"",
        // getSupportData:{},

        portal:"",
        portal_id:"",
        message_count:null,
        login_user_id:"",
        user_level:"",
        selectPage:false,
        year:"",

         formData:{
            search_type:"",
            search_by:"",
            column:[],
            search_value:"",
            view_type:"",
            district:"",
            district_id:"",
            church:"",
            church_id:"",
            evangelism_type_id:"",
            message:"",
            convert_id:[]
         },

         search_typeErr:"",
         search_byErr:"",
         columnErr:"",
         search_valueErr:"",
         view_typeErr:"",
         churchErr:"",
         districtErr:"",
         convertErr:"",
         messageErr:"",
         evangelism_typeErr:"",
         org_name:"",


    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    //...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




    selectAllRecords(){

        axios.get('evangelism_types/all?is_active=' + this.getstatus)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.searchConvert();

    },




   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.searchConvert();

    },




   isChecked(id){

    return  this.checked.includes(id);

   },



   


    perpageval(value){
        this.table_per_page(value);
       this.searchConvert();

    },


    // banSucc(){

    //     this.getSupportMember();

    // },


    searchval(value){

         this.table_search(value);
        this.searchConvert();

    },

    statusval(value){

        this.table_status(value);
        this.searchConvert();

    },

 

 






     







      async get_district_id(){
           try{
       let response = await axios.get(`getDistrictById?district_id=${this.formData.district_id}`);
        console.log(response);
        this.formData.district =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},








  async get_church_id(){
           try{
       let response = await axios.get(`getChurchById?church_id=${this.formData.church_id}`);
        console.log(response);
        this.formData.church =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      // console.log(response);
      
    }catch(error){
        console.log(error);
    }
},





           districtVal(value){

             let field = "District";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.districtErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.districtErr);
      }else{
        if(this.errorClient.includes(this.districtErr))
        {
        let pos = this.errorClient.indexOf(this.districtErr);
        this.errorClient.splice(pos,1);
        }
        this.districtErr =""; 
         this.churchByDistrict();
         this.get_district_id();
         this.getConverts();
      }

    },




   




      churchVal(value){

             let field = "Church";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(this.formData.district_id != "ALL" && FieldValidateService.reqVal2(value,field)){
        this.churchErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.churchErr);
      }else{
        if(this.errorClient.includes(this.churchErr))
        {
        let pos = this.errorClient.indexOf(this.churchErr);
        this.errorClient.splice(pos,1);
        }
        this.churchErr ="";
        this.get_church_id(); 
        this.getConverts();
        this.evangelismType();
      }

    },







         evangelism_typeVal(value){

             let field = "Evangelism Type";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(this.formData.evangelism_type_id != "ALL" && FieldValidateService.reqVal2(value,field)){
        this.churchErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.evangelism_typeErr);
      }else{
        if(this.errorClient.includes(this.evangelism_typeErr))
        {
        let pos = this.errorClient.indexOf(this.evangelism_typeErr);
        this.errorClient.splice(pos,1);
        }
        this.evangelism_typeErr ="";
       // this.get_church_id(); 
      }

    },







 async churchByDistrict(){
    try{

        const response = await axios.get(`getChurchByDistrict?district_id=${this.formData.district_id}`)
        this.getChurches = response.data.data;

       // console.log(this.getChurches);

    }catch(_){

        console.log(_);

    }

    this.getChurches.unshift({"id":"ALL", "church":"ALL"});
},





async getPortal(){


 
if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
   this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
   this.portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
   //console.log(localStorage.getItem("user_portal_info"))
 if(this.app_portal == "CHURCH")
 {
this.formData.district_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district_id;
this.formData.district = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district;
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
this.portal_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church;
this.org_name = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church;
 }
  else if(this.app_portal == "DISTRICT")
 {
  this.formData.district_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district_id;
  this.formData.district = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district;
  this.portal_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district_id;
  this.org_name = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_district;
 }
}
else{


     this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
     this.portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
     //console.log(localStorage.getItem("user_portal_info"))
 if(this.app_portal == "CHURCH")
 {
this.formData.church_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;
this.formData.district_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_district_id;
this.formData.church = JSON.parse(localStorage.getItem("user_portal_info")).portal_church;
this.portal_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;
this.org_name = JSON.parse(localStorage.getItem("user_portal_info")).portal_church;
 }
  else if(this.app_portal == "DISTRICT")
 {
  this.formData.district_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_district_id;
  this.formData.district = JSON.parse(localStorage.getItem("user_portal_info")).portal_district;
   this.portal_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_district_id;
  this.org_name = JSON.parse(localStorage.getItem("user_portal_info")).portal_district;
 }

}
},





          search_typeVal(value) {

      let max = 10;
      let field = "Search Type";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.search_typeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.search_typeErr);
     }else{

           if(this.errorClient.includes(this.search_typeErr))
           {
            let pos = this.errorClient.indexOf(this.search_typeErr);
           this.errorClient.splice(pos,1);
            this.search_typeErr =""; 
           }
          
          

     }
    },






     search_byVal(value){

             let field = "Search By";

                   if(typeof value == "object" && value!=null)
             {
             value = value.search_by;
             }


      if(FieldValidateService.reqVal2(value,field)){
        this.search_byErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.search_byErr);
      }else{
        if(this.errorClient.includes(this.search_byErr))
        {
        let pos = this.errorClient.indexOf(this.search_byErr);
        this.errorClient.splice(pos,1);
        }
        
        this.search_byErr =""; 
      }

    },





         columnVal(value){

             let field = "Column";

              if(typeof value == "object" && value!=null)
             {
                  value.push(value.column);
             }

      if(this.formData.search_type=='DYNAMIC' && FieldValidateService.reqVal2(value,field)){
        this.columnErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.columnErr);
      }else{
        if(this.errorClient.includes(this.columnErr))
        {
        let pos = this.errorClient.indexOf(this.columnErr);
        this.errorClient.splice(pos,1);
        }
        this.columnErr =""; 
      }

    },






          search_valueVal(value) {

      let max = 500;
      let field = "Search Value";
     
     if(this.formData.search_by != 'ALL' && FieldValidateService.reqVal(value,field,max))
     {
       this.search_valueErr = FieldValidateService.reqVal(value,field,max);
        this.errorClient.push(this.search_valueErr);
     }else{

           if(this.errorClient.includes(this.search_valueErr))
           {
            let pos = this.errorClient.indexOf(this.search_valueErr);
           this.errorClient.splice(pos,1);
            this.search_valueErr =""; 
           }
          
          

     }
    },







    view_typeVal(value) {

      let max = 10;
      let field = "Value Type";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.view_typeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.view_typeErr);
     }else{

           if(this.errorClient.includes(this.view_typeErr))
           {
            let pos = this.errorClient.indexOf(this.view_typeErr);
           this.errorClient.splice(pos,1);
            this.view_typeErr =""; 
           }
          
          

     }
    },







        convertVal(value){

             let field = "Converts";


              if(typeof value == "object" && value!=null)
             {
                  value.push(value.id);
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.convertErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.convertErr);
      }else{
        if(this.errorClient.includes(this.convertErr))
        {
        let pos = this.errorClient.indexOf(this.convertErr);
        this.errorClient.splice(pos,1);
        }
        this.convertErr =""; 
      }

      if(this.formData.convert_id[0] == ""){
          let pos = this.formData.convert_id.indexOf(null);
        this.formData.convert_id.splice(pos,1);
      }

    },





    messageVal(value) {

      let max = 50000;
      let field = "Message";
     
     //onsole.log(value.length);
     if(FieldValidateService.reqVal(value,field,max))
     {
       this.messageErr = FieldValidateService.reqVal(value,field,max);
        this.errorClient.push(this.messageErr);
     }else{

           if(this.errorClient.includes(this.messageErr))
           {
            let pos = this.errorClient.indexOf(this.messageErr);
           this.errorClient.splice(pos,1);
            this.messageErr ="";
            
           }


           this.message_count = value.length; 
         //    console.log(this.message_count);
          
          

     }
    },






    printDiv(){

          this.$refs.easyPrint.print()

    },




    async getConverts(){
        try{
       let response = await axios.get(`getConvertSms?district_id=${this.formData.district_id}&&church_id=${this.formData.church_id}&&evangelism_type_id=${this.formData.evangelism_type_id}`);
       this.getConvertss = response.data.data;
      console.log(this.getConvertss);
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
      
    }catch(_){
        console.log(_);
    }

       this.getConvertss.unshift({"id":"ALL", "full_name":"ALL"});

    },


    async searchConvert(){

       this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);

        this.districtVal(this.formData.district_id);
        this.churchVal(this.formData.church_id);
        this.search_typeVal(this.formData.search_type);
        this.search_byVal(this.formData.search_by);
        this.columnVal(this.formData.column);
        this.search_valueVal(this.formData.search_value);
        this.view_typeVal(this.formData.view_type);


          if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }

       else{

   


                 try{


                   const formData = {

            search_type: this.formData.search_type,
            search_by: this.formData.search_by,
            column: this.formData.column,
            search_value: this.formData.search_value,
            view_type: this.formData.view_type,
            district: this.formData.district,
            district_id: this.formData.district_id,
            church: this.formData.church,
            church_id: this.formData.church_id,
            table_search: this.getsearch,
            page_size:  this.perpage,
            sort_direction: this.sort_direction,
            sort_field: this.sort_field
            
        };


            let response = await axios.post("searchConvert",formData);
              if(response.status == 200)
              {
                   this.showLoading(false);

                   this.getTotalConver();
                     //this.clearFormData();
        // notificationBox.succNotifications("Convert Added Successfully");
        // this.$emit("convertAdded");


               if(this.formData.view_type == "GRID")
               {
               
               this.grid_converts = [];
                this.grid_converts = response.data.data.data;
                console.log(response.data.data.data);

                   this.table_per_page(response.data.data.per_page);
             this.table_total(response.data.data.total)
              
               }
               else{
                this.report_converts = response.data.data;
                this.year = new Date().getFullYear(); 
               }
              }


         }catch(error){

                  this.showLoading(false);
                 console.log(error);
      //      this.errorServers.push(error.response.data.error);
           
      //      this.showLoading(false);
           

      //             if(this.errorServers.length > 0)
      //  {
      //     notificationBox.errorNotifications("Some Errors Exist");
      //       this.getServerError();
     
      //  }
      //  else if(error.response.data.data_error){
        
      //      notificationBox.errorNotifications(error.response.data.data_error);
      //  }

         }

       }


    },








     async evangelismType(){
    try{

        const response = await axios.get(`getEvangelismTypes?church_id=${this.formData.church_id}`)
        this.getEvangelismTypes = response.data.data;

       // console.log(this.getChurches);

    }catch(_){

        console.log(_);

    }

    this.getEvangelismTypes.unshift({"id":"ALL", "evangelism_type":"ALL"});
},






    async sendSms(){

               this.errorServers = [];
       this.errorClient = [];
       this.showLoading(true);

        this.districtVal(this.formData.district_id);
        this.churchVal(this.formData.church_id);
        this.convertVal(this.formData.convert_id);
         this.evangelism_typeVal(this.formData.evangelism_type_id);
        this.messageVal(this.formData.message);

        console.log(this.formData.message);

             if(this.errorClient.length > 0)
       {
         this.showLoading(false);
          notificationBox.errorNotifications("Some Errors Exist");
          console.log(this.errorClient);
          return true;
       }
       else{

        
                 try{


                   const formData = {

            convert_id: this.formData.convert_id,
            app_portal: this.app_portal,
            district: this.formData.district,
            district_id: this.formData.district_id,
            message: this.formData.message,
            church: this.formData.church,
            church_id: this.formData.church_id,
            evangelism_type_id: this.formData.evangelism_type_id,
            portal: this.portal,
            portal_id:this.portal_id,
            
            
        };


            let response = await axios.post("sendConvertSms",formData);
              if(response.status == 200)
              {
                   this.showLoading(false);

                     this.clearFormData();
         notificationBox.succNotifications("Convert Added Successfully");


              
              }


         }catch(error){

               console.log(error);
                  this.showLoading(false);

        if(this.errorServers.length > 0)
       {
          
            this.getServerError();
     
       }
       else{

         notificationBox.errorNotifications("Some Errors Exist");
  
                 notificationBox.errorNotifications(error.response.data.error);
       }



         }

       }



    },





         clearFormData(){
        this.errorServers = [];
        this.errorClient = [];
      //this.clear_user = false;
      this.formData.district = "";
      this.formData.district_id = "";
      this.formData.church = "";
      this.formData.church_id = "";
      this.formData.convert_id = [];
      this.formData.message = "";
 


    },




   getServerError(){

       this.districtErr = 'district_id' in this.errorServers[0] ? this.errorServers[0].district_id[0] : '';
       this.churchErr = 'church_id' in this.errorServers[0] ? this.errorServers[0].church_id[0] : '';
       this.convertErr = 'convert_id' in this.errorServers[0] ? this.errorServers[0].convert_id[0] : '';
       this.messageErr = 'message' in this.errorServers[0] ? this.errorServers[0].message[0] : '';



     
           return true;

   },







  
  },





 async created(){


    //  try{
    //    let response = await axios.get(`getTotalConverts?app_portal=${this.app_portal}`);
    //     this.totalConverts =  JSON.parse(JSON.stringify(response.data.data));
    //    // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
    //     //console.log("meeeeeeee");
       
       
      
    // }catch(_){
    //     console.log(_);
    // }


        try{
       let response = await axios.get("getDistricts");
        this.getDistricts =  JSON.parse(JSON.stringify(response.data.data));
       // this.getPortalDenominations =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
       
       
      
    }catch(error){
        console.log(error);
    }

    this.getDistricts.unshift({"id":"ALL", "District":"ALL"});


this.getPortal();
 this.churchByDistrict();
       
      
// this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
// this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
//console.log(JSON.parse(localStorage.getItem("user_portal_info")))

    //this.currentPage = 1;
    this.table_current_page(1);
    this.evangelismType();
    this.formData.evangelism_type_id = "ALL";
    this.getConverts();

      //  this.evangelismType();
       //  this.getEvangelismType();
 

    this.getSearchBy = [
        {
                "search_by":"ALL",
                "search_by_act_name":"ALL",
            },
            {
                "search_by":"evangelism_types.evangelism_type",
                "search_by_act_name":"EVANGELISM TYPE",
            },
            // {
            //     "search_by":"denominations.denomination",
            //     "search_by_act_name":"DENOMINATION",
            // },
            // {
            //     "search_by":"churches.church",
            //     "search_by_act_name":"CHURCH",
            // },
            {
                "search_by":"converts.fullname",
                "search_by_act_name":"FULLNAME",
            },
            {
                "search_by":"converts.age",
                "search_by_act_name":"AGE",
            },
            {
                "search_by":"age>=",
                "search_by_act_name":"AGE>=",
            },
            {
                "search_by":"age<=",
                "search_by_act_name":"AGE<=",
            },
            {
                "search_by":"converts.gender",
                "search_by_act_name":"GENDER",
            },
            {
                "search_by":"converts.location",
                "search_by_act_name":"LOCATION",
            },
            {
                "search_by":"converts.residence_phone_number",
                "search_by_act_name":"RESIDENCE PHONE NUMBER",
            },
            {
                "search_by":"converts.office_phone_number",
                "search_by_act_name":"OFFICE PHONE NUMBER",
            },
            {
                "search_by":"converts.first_time_decision_for_christ",
                "search_by_act_name":"FIRST TIME DECISION FOR CHRIST",
            },
            {
                "search_by":"converts.best_time_to_visit",
                "search_by_act_name":"BEST TIME TO VISIT",
            },
            {
                "search_by":"converts.convert_needs",
                "search_by_act_name":"CONVERT NEEDS",
            },
    ];

   // console.log(JSON.parse(JSON.stringify(this.getSearchBy)));

    this.getColumns = [{
                "column":"evangelism_type",
                "column_view":"EVANGELISM TYPE",
                "column_field":"evangelism_types.evangelism_type",
            },
            {
                "column":"district",
                "column_view":"DISTRICT",
                "column_field":"districts.district",
            },
            {
                "column":"church",
                "column_view":"CHURCH",
                 "column_field":"churches.church",
            },
            
            {
                "column":"fullname",
                "column_view":"FULLNAME",
                "column_field":"converts.fullname",
            },
            {
                "column":"age",
                "column_view":"AGE",
                "column_field":"converts.age",
            },
            {
                "column":"gender",
                "column_view":"GENDER",
                "column_field":"converts.gender",
            },
            {
                "column":"location",
                "column_view":"LOCATION",
                "column_field":"converts.location",
            },
            {
                "column":"residence_phone_number",
                "column_view":"RESIDENCE PHONE NUMBER",
                "column_field":"converts.residence_phone_number",
            },
            {
                "column":"office_phone_number",
                "column_view":"OFFICE PHONE NUMBER",
                "column_field":"converts.office_phone_number",
            },
            {
                "column":"first_time_decision_for_christ",
                "column_view":"FIRST TIME DECISION FOR CHRIST",
                "column_field":"converts.first_time_decision_for_christ",
            },
            {
                "column":"best_time_to_visit",
                "column_view":"BEST TIME TO VISIT",
                "column_field":"converts.best_time_to_visit",
            },
            {
                "column":"convert_needs",
                "column_view":"CONVERT NEEDS",
                "column_field":"converts.convert_needs",
            }];
  },


  watch:{


     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const evangelism_types = JSON.parse(JSON.stringify(this.evangelism_types));
            evangelism_types.forEach(evangelism_type=>{
                this.checked.push(evangelism_type.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "evangelism_types/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>


<style scoped src="@/assets/css/report.css"></style>